import React from "react";
import { Trans } from "@lingui/macro";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import styled from "@emotion/styled";
import NewLayout from "../../components/NewLayout";
import Hero from "../../components/Hero";
import Container from "../../components/Container";
import backgroundImage from "../../images/headers/fund-houses-investment.png";
import BoxDetails from "../../components/BoxDetails";
import structuremImage from "../../images/fund-houses/investment/structure.png";
import mancoImage from "../../images/fund-houses/investment/manco.png";
import InterestedItems from "../../components/InterestedItems";
import { additionalLinks, headers, restHeaders } from "../../utils/navbar";
import ArrowButton from "../../components/ArrowButton";

const content = () => [
  {
    title: <Trans>Manco Services</Trans>,
    description: (
      <StyledStack spacing={1}>
        <Typography>
          <Trans>
            As a Luxembourg management company, Allfunds Investment Solutions
            can support Distributors and Fund Houses in a variety of services,
            reducing their administrative burden and enabling them to broaden
            their commercial reach.
          </Trans>
        </Typography>
        <Box>
          <Typography>
            <b>
              <Trans>Fund hosting and private labelling</Trans>
            </b>
          </Typography>
          <Typography>
            <Trans>
              Our fund hosting & private labelling offering creates a
              sustainable tie to fund houses and distributors.
            </Trans>
          </Typography>
        </Box>
        <Box>
          <Typography>
            <b>
              <Trans>
                Your unique strategic partner in creating and distributing your
                UCITS funds globally
              </Trans>
            </b>
          </Typography>
          <Typography>
            <Trans>
              We combine the global reach of the world’s largest fund
              distribution platform with the local expertise of a Luxembourgish
              Management Company to deliver a 360⁰ service to our clients. Our
              services are tailored precisely to your needs and goals. Our
              client base is spread across five continents and diverse asset
              classes and strategies. We have partnered with asset managers,
              family offices, banks and initiators to create their bespoke fund
              vehicles and help them reach a global investor base.
            </Trans>
          </Typography>
        </Box>
        <Box>
          <Typography>
            <Trans>
              BNP Paribas Luxembourg acts as our depositary. This legally
              separates the depositary from the management company, ensuring
              maximum independence, checks and balances – while maintaining
              service from a single point of contact.
            </Trans>
          </Typography>
        </Box>
      </StyledStack>
    ),
    image: mancoImage,
  },
  {
    title: <Trans>Delegated Fund Structuring</Trans>,
    description: (
      <StyledStack>
        <ul>
          <li>
            <Trans>Bespoke advice on fund creation and product design</Trans>
          </li>
          <li>
            <Trans>
              Coordination of the entire onboarding process with all partners
              involved
            </Trans>
          </li>
          <li>
            <Trans>
              Creation of the official fund documents, such as prospectus,
              KIIDs, factsheets, etc.
            </Trans>
          </li>
          <li>
            <Trans>
              Support and advice on the implementation of regulatory
              requirements reg. ESG & SFDR
            </Trans>
          </li>
          <li>
            <Trans>
              Communication and fund approval process coordination with the
              supervisory authorities in Luxembourg
            </Trans>
          </li>
          <li>
            <Trans>Single point of contact for your day-to-day business</Trans>
          </li>
          <li>
            <Trans>Risk management and investment guidance</Trans>
          </li>
          <li>
            <Trans>
              Depositary in Luxembourg with extensive experience, the highest
              rating, and a worldwide network of sub-custodians
            </Trans>
          </li>
          <li>
            <Trans>
              Fund accounting performed by a first-rate provider; reports
              individually tailored to your needs
            </Trans>
          </li>
        </ul>
      </StyledStack>
    ),
    image: structuremImage,
  },
  // {
  //   title: <Trans>Global Distribution</Trans>,
  //   description: (
  //     <StyledStack spacing={1}>
  //       <Typography>
  //         <Trans>
  //           We take care of the distribution registrations of your fund in the
  //           jurisdictions of your choice. We provide support for your fund sales
  //           through the largest fund distribution platform worldwide. We
  //           streamline your access to fund distributors and help you to meet
  //           regulatory requirements and reduce legal fees by:
  //         </Trans>
  //       </Typography>
  //       <Box>
  //         <ul>
  //           <li>
  //             <Trans>
  //               Entering into new and maintaining existing legal agreements with
  //               the distributors of your choice
  //             </Trans>
  //           </li>
  //           <li>
  //             <Trans>Coordinating between the platform and our clients</Trans>
  //           </li>
  //         </ul>
  //       </Box>
  //     </StyledStack>
  //   ),
  //   image: digitalImage,
  // },
];

function FundHouseInvestmentPage() {
  const interestedItems = [
    { ..._.get(restHeaders(), "about.elements.overview") },
    { ..._.get(additionalLinks(), "blockchain") },
    { ..._.get(restHeaders(), "distributors") },
    { ..._.get(restHeaders(), "fund_houses", {}) },
    { ..._.get(headers(), "contact", {}) },
    { ..._.get(headers(), "esg.elements.approach_esg", {}) },
    { ..._.get(restHeaders(), "about.elements.about_team", {}) },
    { ..._.get(restHeaders(), "about.elements.about_history", {}) },
  ];

  return (
    <NewLayout>
      <Hero
        kind={<Trans>ManCo & Investment Solutions</Trans>}
        title={<Trans>ManCo & Investment Solutions</Trans>}
        description={
          <Trans>
            Allfunds provides ManCo services and investment solutions for banks,
            wealth managers and institutional investors. From fund structuring
            to investment management delegation, Allfunds’ robust service
            offering, combined its extensive fund distribution network, helps
            partners streamline their operations and maximise their sales
            efforts.
          </Trans>
        }
        backgroundImage={backgroundImage}
        buttonText={<Trans>Get in touch</Trans>}
        buttonPath="/contact"
      />
      <Container py={8}>
        <Typography variant="h3">
          <Trans>
            Amplify your product range and benefit from economies of scale
          </Trans>
        </Typography>
        <Stack mt={3} spacing={2}>
          {content().map(({ title, description, image }) => (
            <BoxDetails title={title} description={description} image={image} />
          ))}
        </Stack>
        <Box mt={3} display="flex" justifyContent="end">
          <ArrowButton
            title={<Trans>Access our ManCo website</Trans>}
            path="https://allfunds-is.com/"
            isDark
          />
        </Box>
        <InterestedItems isSmall items={interestedItems} />
      </Container>
    </NewLayout>
  );
}

const StyledStack = styled(Stack)`
  > p,
  * p,
  * li,
  * b {
    font-size: 18px;
  }
`;

export default FundHouseInvestmentPage;
